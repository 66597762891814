export const kultLetoList = [
  {
    id: 1,
    date: '12, 15 июня',
    name: '«Здравствуй, лето»',
    subscribe: 'Для ребенка очень важно научится правильно выражать свои эмоции и свою фантазию, на данном интерактиве ваш ребенок научится это делать через рисунок, узнает основные правила рисования, весело проведет время.',
    age: '7 – 12 лет',
    shedule: '11:00 – 12:00',
    maxNumberMembers: '8',
    duration: '2 дня',
    price: 'Бесплатно',
    loacation: 'Дом культуры «Юбилейный»',
    img: require('../../assets/images/leto/kultLeto/kult-1.jpg')
  },
  {
    id: 2,
    date: '14, 16 июня',
    name: '«Я узнаю цирк»',
    subscribe: 'Каждый ребенок знает, что такое цирк – это смех, веселье, клоуны,  фокусники и ловкие люди. Но редко кто из взрослых может объяснить, в чем особенности цирка как вида искусства. Ведь мы его чаще всего представляем, как увлекательное зрелище, шоу. За дни проведения интенсива ваши дети расширят познания о цирке, окунутся в эту волшебную атмосферу.',
    age: '7 – 12 лет',
    shedule: '11:00 – 12:00',
    maxNumberMembers: '10',
    duration: '2 дня',
    price: 'Бесплатно',
    loacation: 'Дом культуры «Юбилейный»',
    img: require('../../assets/images/leto/kultLeto/kult-2.jpg')
  },
  {
    id: 3,
    date: '13, 15 июня',
    name: '«Игры наших родителей»',
    subscribe: 'В чем главное отличие детства современного от того что было 20-25 лет назад – так это в количестве проводимого с друзьями времени на улице. За различными подвижными и веселыми играми, позволяющими выплеснуть всю неукротимую энергию и вдоволь пообщаться с ребятней. Такие игры мы и постараемся показать вашим детям. ',
    age: '7 – 12 лет',
    shedule: '11:00 – 12:00',
    maxNumberMembers: '20',
    duration: '2 дня',
    price: 'Бесплатно',
    loacation: 'Дом культуры «Звёздный»',
    img: require('../../assets/images/leto/kultLeto/kult-3.jpg')
  },
  {
    id: 4,
    date: '14,16 июня',
    name: '«Поделки из бросового материала»',
    subscribe: 'Популярным направлением в современном творчестве считается изготовление поделок с использованием бросового материала, которые подходят для детей любого возраста. В работе используют предметы, бывшие в употреблении и ставшие ненужными: старые газеты, CD-диски, виниловые пластинки, бутылки, пробки, полиэтиленовые пакеты, коробки, консервные банки и многое другое. Данное искусство защищает окружающую среду от загрязнений, дает вторую жизнь старым вещам, утратившим свое прямое назначение, а также помогает детям проявить себя в творчестве.',
    age: '7 – 12 лет',
    shedule: '11:00 – 12:00',
    maxNumberMembers: '8',
    duration: '2 дней',
    price: 'Бесплатно',
    loacation: 'Дом культуры «Звездный»',
    img: require('../../assets/images/leto/kultLeto/kult-4.jpg')
  },
  {
    id: 5,
    date: '19, 21, 23 июня',
    name: '«Искусство кукол»',
    subscribe: 'Кукольный театр – одно из самых любимых зрелищ детей дошкольного возраста. ... Кукольный театр доставляет детям большое удовольствие и приносит много радости. Ваши дети узнают много интересного о данном направлении и окунутся в эту волшебную атмосферу.',
    age: '7 – 12 лет',
    shedule: '13:00 – 14:00',
    maxNumberMembers: '8',
    duration: '2 дня',
    price: 'Бесплатно',
    loacation: 'Дом культуры «Юбилейный»',
    img: require('../../assets/images/leto/kultLeto/kult-5.jpg')
  },
  {
    id: 6,
    date: '19, 21, 23 июня',
    name: '«Искусство фланкировки с 7 лет»',
    subscribe: 'Фланкировка — это казачий “танец”, а именно упражнение с клинковым оружием. Это очень красивые упражнения, требующие от казака больших умений. Многие, кто наблюдал, как профессионалы виртуозно используют казачью шашку, испытывали восторг и восхищение. В этом году мы решили данное направление включить в программу летней творческой лаборатории, с целью приобщения наших юных краснопахорцев к данному виду искусства.',
    age: '7 лет',
    shedule: '11:00 – 12:00',
    maxNumberMembers: '8',
    duration: '3 дня',
    price: 'Бесплатно',
    loacation: 'Дом культуры «Звездный»',
    img: require('../../assets/images/leto/kultLeto/kult-6.jpg')
  },
  {
    id: 7,
    date: '20, 22 июня',
    name: '«Игры наших родителей»',
    subscribe: 'В чем главное отличие детства современного от того что было 20-25 лет назад – так это в количестве проводимого с друзьями времени на улице. За различными подвижными и веселыми играми, позволяющими выплеснуть всю неукротимую энергию и вдоволь пообщаться с ребятней. Такие игры мы и постараемся показать вашим детям. ',
    age: '7 – 12 лет',
    shedule: '11:00 – 12:00',
    maxNumberMembers: '20',
    duration: '2 дня',
    price: 'Бесплатно',
    loacation: 'Дом культуры «Юбилейный»',
    img: require('../../assets/images/leto/kultLeto/kult-3.jpg')
  },
  {
    id: 8,
    date: '20, 22 июня',
    name: '«Учимся шить»',
    subscribe: 'Основная цель интенсива открыть ребенку увлекательный мир рукоделия, дать ему почувствовать себя творцом. Данный вид искусства развивает мелкую моторику и вырабатывает в ребенке усидчивость. ',
    age: '7 – 12 лет',
    shedule: '11:00 – 12:00',
    maxNumberMembers: '10',
    duration: '2 дня',
    price: 'Бесплатно',
    loacation: 'Дом культуры «Звездный»',
    img: require('../../assets/images/leto/kultLeto/kult-8.jpg')
  },
]