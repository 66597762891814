export const eventsConfig = [
    {
        id: 12,
        eventDay: '24',
        eventDayNumber: 'ВС',
        eventInfoDate: '24 ноября',
        eventInfoTitle: 'Конкурсная программа',
        eventDescription: '24 ноября в 12-00ч. состоится ежегодная конкурсная программа "Звезда по имени мама"',
        eventCost: 'ДК "Звёздный"',
        eventImgSrc: require('../../assets/images/eventsImages/mama_zvezda-01.jpg'),
        eventsPreviewImg: require('../../assets/images/eventsImages/eventsPreviewImgs/mama_zvezda-02.jpg'),
    },
    {
        id: 11,
        eventDay: '18',
        eventDayNumber: 'ПН',
        eventInfoDate: '18 ноября',
        eventInfoTitle: 'Игровая-программа',
        eventDescription: '18 ноября в 18 часов ждём вас в ДК "Звёздный".Приходите на игровую-программу "Как выглядит Дедушка Мороз" ',
        eventCost: 'ДК "Звёздный"',
        eventImgSrc: require('../../assets/images/eventsImages/ded-01.jpg'),
        eventsPreviewImg: require('../../assets/images/eventsImages/eventsPreviewImgs/ded-02.jpg'),
    },
    {
        id: 12,
        eventDay: '17',
        eventDayNumber: 'ВС',
        eventInfoDate: '17 ноября',
        eventInfoTitle: 'Венок дружбы',
        eventDescription: 'Мы готовим для вас 10 межрегиональный фестиваль, посвященный Международному дню толерантности 17 ноября в 12-00ч.',
        eventCost: 'ДК "Звёздный"',
        eventImgSrc: require('../../assets/images/eventsImages/venok-01.jpg'),
        eventsPreviewImg: require('../../assets/images/eventsImages/eventsPreviewImgs/venok-02.jpg'),
    },
    {
        id: 12,
        eventDay: '16',
        eventDayNumber: 'СБ',
        eventInfoDate: '16 ноября',
        eventInfoTitle: 'Спектакль',
        eventDescription: 'СКОРО В ДК "ЮБИЛЕЙНЫЙ" 16 ноября в  в 13 часов актёры коллектива "Арлекин" и режиссёр Елена Матвеева представят новый спектакль: "Как-то раз под Новый год", а также историю о том, как появился Дед Мороз. Приходите! Будет интересно!',
        eventCost: 'ДК "Юбилейный"',
        eventImgSrc: require('../../assets/images/eventsImages/kak-to raz-01.jpg'),
        eventsPreviewImg: require('../../assets/images/eventsImages/eventsPreviewImgs/kak-to raz-02.jpg'),
    },
    {
        id: 11,
        eventDay: '09',
        eventDayNumber: 'СБ',
        eventInfoDate: '09 ноября',
        eventInfoTitle: 'Развлекательная программа',
        eventDescription: 'КО ДНЮ ДОБРОТЫ 9 ноября в 12 часов в доме культуры села Былово состоится развлекательная программа для детей. Методисты структурного подразделения ДК "Звёздный" подготовили весёлые конкурсы и игры. Участников ждут сладкие призы!',
        eventCost: 'ДК "Звёздный"',
        eventImgSrc: require('../../assets/images/eventsImages/dobro-01.jpg'),
        eventsPreviewImg: require('../../assets/images/eventsImages/eventsPreviewImgs/dobro-02.jpg'),
    },
    {
        id: 11,
        eventDay: '02',
        eventDayNumber: 'СБ',
        eventInfoDate: '02 ноября',
        eventInfoTitle: 'Спектакль "Сказка на ночь"',
        eventDescription: '2 ноября в 18 часов ждём вас в ДК "Звёздный".Пожалуйста, приходите заранее, чтобы успеть занять свободное место в зрительном зале',
        eventCost: 'ДК "Звёздный"',
        eventImgSrc: require('../../assets/images/eventsImages/na no4-01.jpg'),
        eventsPreviewImg: require('../../assets/images/eventsImages/eventsPreviewImgs/na no4-02.jpg'),
    },
    {
        id: 1,
        eventDay: '01',
        eventDayNumber: 'ПТ',
        eventInfoDate: '01-23 ноября',
        eventInfoTitle: 'Расписание мероприятий ДК «Юбилейный»',
        eventDescription: 'С 01-23 ноября мероприятия в Домах культуры «Звездный» и «Юбилейный» ',
        eventCost: 'ДК "Юбилейный"',
        eventImgSrc: require('../../assets/images/eventsImages/obschB11-01.jpg'),
        eventsPreviewImg: require('../../assets/images/eventsImages/eventsPreviewImgs/obschB11-02.jpg'),
    },
    {
        id: 10,
        eventDay: '01',
        eventDayNumber: 'ПТ',
        eventInfoDate: '01-18 ноября',
        eventInfoTitle: 'Расписание мероприятий ДК «Звездный»',
        eventDescription: 'С 01-18 ноября мероприятия в Домах культуры «Звездный» и «Юбилейный» ',
        eventCost: 'ДК "Звёздный"',
        eventImgSrc: require('../../assets/images/eventsImages/obsch11-01.jpg'),
        eventsPreviewImg: require('../../assets/images/eventsImages/eventsPreviewImgs/obsch11-02.jpg'),
    },
    {
        id: 9,
        eventDay: '01',
        eventDayNumber: 'ПТ',
        eventInfoDate: '01 ноября',
        eventInfoTitle: 'Спектакль "Сказка на ночь"',
        eventDescription: '1 ноября в 18 часов в зале ДК "Звёздный" состоится концерт ко Дню народного единства. В программе выступления солистов творческих коллективов дома культуры',
        eventCost: 'ДК "Звёздный"',
        eventImgSrc: require('../../assets/images/eventsImages/DNE2024-01.jpg'),
        eventsPreviewImg: require('../../assets/images/eventsImages/eventsPreviewImgs/DNE2024-02.jpg'),
    },
    {
        id: 8,
        eventDay: '20',
        eventDayNumber: 'ВС',
        eventInfoDate: '20 октября',
        eventInfoTitle: 'Конкурсно-игровая программа',
        eventDescription: 'Мы готовим для вас 20 октября в 12-00 конкурсно-игровую программу под названием "Лучший папа" приводите пап и участвуйте сами',
        eventCost: 'ДК "Звёздный"',
        eventImgSrc: require('../../assets/images/eventsImages/papaa-01.jpg'),
        eventsPreviewImg: require('../../assets/images/eventsImages/eventsPreviewImgs/papaa-02.jpg'),
    },
]